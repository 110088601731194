import { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { isMobile } from "react-device-detect";
import { X } from "lucide-react";

export default function Chat({
  messages,
  imageUrl,
  firstName,
  sendMessage,
  inputValue,
  setInputValue,
  mobile,
  setShowMobileChat,
}: {
  messages: any[];
  imageUrl: any;
  firstName: any;
  sendMessage: any;
  inputValue: any;
  setInputValue: any;
  mobile?: any;
  setShowMobileChat?: any;
}) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      //@ts-ignore
      messagesEndRef?.current?.scrollIntoView();
    }
  }, [messages]);

  const disabled = !firstName || !imageUrl;

  return (
    <div
      className={
        isMobile && mobile
          ? "fixed top-0 right-0 w-[65vw] h-[70vh] bg-black z-50 opacity-95 border-l-4 border-b-4 overflow-hidden touch-none"
          : !mobile
          ? "hidden sm:flex w-[300px] md:w-[300px] xl:w-[350px] h-full p-6 sm:pt-2 md:pt-2 md:p-6 xl:p-8 xl:pt-2 flex flex-col relative"
          : "hidden"
      }
    >
      {messages.length > 5 && !mobile && (
        <div className="absolute inset-x-0 top-0 h-40 bg-gradient-to-b from-black to-transparent z-10 pointer-events-none"></div>
      )}
      <div
        className={`h-full ${
          isMobile ? "p-2" : ""
        } flex flex-col relative touch-none`}
      >
        {mobile && (
          <Button
            className="absolute top-2 right-2 px-2"
            onClick={() => setShowMobileChat(false)}
          >
            <X className="w-5 h-5" />
          </Button>
        )}
        <div className={`flex-1 overflow-y-auto mb-2 sm:mb-4 touch-none`}>
          {messages.map((message, index) => (
            <div
              key={index}
              className="bg-transparent text-white p-1 sm:p-2 my-0.5 sm:my-1 rounded flex items-start"
            >
              <div
                className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-center bg-cover mr-3 shrink-0 mt-2"
                style={{ backgroundImage: `url(${message.imageUrl})` }}
              />
              <div>
                <div className="text-sm sm:text-md">
                  <strong>{message.firstName}</strong>
                </div>
                <div className="text-[13px] sm:text-[14px]">
                  {message.content}
                </div>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>
        <div className="flex items-center">
          <div
            className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-cover bg-center mr-2 shrink-0"
            style={{
              backgroundImage: disabled
                ? `url(/agentmaxx-logo-transparent.png)`
                : `url(${imageUrl})`,
            }}
          />
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
            maxLength={1000}
            placeholder="type your message..."
            className="bg-transparent text-white p-2 rounded flex-1 text-md outline-none"
          />
        </div>
        <Button
          onClick={sendMessage}
          disabled={disabled}
          className="font-bold sm:font-extrabold mt-2 px-0.5 py-0.5 sm:px-2 sm:py-4 text-sm sm:text-md"
        >
          {disabled ? "get analyzed to unlock chat" : "send"}
        </Button>
      </div>
    </div>
  );
}
