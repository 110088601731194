import { Loader2 } from "lucide-react";

export default function Queue({ queue }: { queue: any }) {
  return (
    <div className="hidden sm:block w-[300px] md:w-[300px] xl:w-[350px] shrink-0 p-6 sm:pt-2 md:pt-2 md:p-6 xl:p-8 xl:pt-2">
      <div className="flex flex-col-reverse justify-start h-full">
        {queue?.map((item: any, i: any) => (
          <div key={`${item.firstName}-${i}`}>
            {i === 0 && (
              <>
                <div className="mt-8">up next</div>
                <div className="border-t border-zinc-900 mt-2" />
              </>
            )}
            <div className="mt-2">
              <div className="flex items-center justify-between bg-zinc-900 pr-6">
                <div className="flex items-center">
                  <div
                    className="w-14 h-14 bg-cover bg-center"
                    style={{ backgroundImage: `url(${item.imageUrl})` }}
                  />
                  <div className="ml-6 font-extrabold">{item.firstName}</div>
                </div>
                <Loader2 className="animate-spin w-4 h-4" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
