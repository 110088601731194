import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { CameraIcon, FileUpIcon, Loader2, RecycleIcon } from "lucide-react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";

export default function SelfieModal({
  open,
  onClose,
  onSubmitImage,
  submittingImage,
}: any) {
  const [takePhoto, setTakePhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const [name, setName] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTakePhoto(false);
    setSelectedPhoto(null);
    setName("");
  }, [open]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const handleTakePhoto = (dataUri: any) => {
    setSelectedPhoto(dataUri);
    setTakePhoto(false);
  };

  const handleSubmit = async () => {
    if (!selectedPhoto || !name) return;

    const submissionData = {
      image: selectedPhoto,
      firstName: name,
    };

    onSubmitImage(submissionData);
  };

  return (
    <Dialog open={open} onOpenChange={(open) => !open && onClose()}>
      <DialogContent
        className={
          takePhoto
            ? "max-h-[95vh] sm:max-h-[90vh] max-w-[95vw] sm:max-w-[90vw]"
            : ""
        }
      >
        <DialogHeader>
          <DialogTitle className="text-3xl">upload a selfie</DialogTitle>
          <DialogDescription>
            get your facial attractiveness analyzed by agentmaxx
          </DialogDescription>
        </DialogHeader>
        <Separator className="my-4" />
        {!selectedPhoto ? (
          <>
            {takePhoto ? (
              <Camera
                idealFacingMode={FACING_MODES.USER}
                onTakePhoto={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
              />
            ) : (
              <div className="flex flex-col sm:flex-row items-center justify-center my-24">
                <Button variant="outline" onClick={() => setTakePhoto(true)}>
                  <CameraIcon className="mr-2" />
                  take a selfie
                </Button>
                <div className="mx-8 my-6 sm:my-0">or</div>
                <Button
                  variant="outline"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <FileUpIcon className="mr-2" />
                  upload a selfie
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="flex items-center justify-center">
              <div
                className="w-[100px] h-[100px] bg-cover bg-center rounded-full mr-8"
                style={{ backgroundImage: `url(${selectedPhoto})` }}
              />

              <Button variant="outline" onClick={() => setSelectedPhoto(null)}>
                <RecycleIcon className="mr-2" />
                change selfie
              </Button>
            </div>
            <div className="mt-8">
              <Label htmlFor="name" className="text-lg">
                your first name
              </Label>
              <Input
                id="name"
                placeholder="enter your first name"
                className="text-md sm:text-sm mt-3"
                value={name}
                maxLength={20}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                onChange={(e) => setName(e.target.value.toLowerCase())}
              />
            </div>

            <DialogFooter className="mt-8">
              <div className={"w-full"}>
                <Button
                  type="submit"
                  className={`w-full`}
                  disabled={name.length < 1 || submittingImage}
                  onClick={handleSubmit}
                >
                  {submittingImage ? (
                    <Loader2 className="animate-spin w-4 h-4" />
                  ) : (
                    "get analyzed"
                  )}
                </Button>
              </div>
            </DialogFooter>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
