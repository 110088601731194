import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import { MessageSquare, MoveDiagonal } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { isMobile } from "react-device-detect";

const categories = [
  {
    category: "skin",
    name: "skin",
    mobileName: "skin",
    emoji: "🧴",
    female: true,
    categoryTag: "skinCategoryRating",
    categoryImproveTag: "skinHowToImprove",
    subcategories: [
      {
        name: "Clear Skin",
        tag: "skinClearSkinRating",
        description: "skinClearSkinRatingDescription",
      },
      {
        name: "Even Skin Tone",
        tag: "skinEvenSkinToneRating",
        description: "skinEvenSkinToneRatingDescription",
      },
      {
        name: "Smooth Texture",
        tag: "skinSmoothTextureRating",
        description: "skinSmoothTextureRatingDescription",
      },
      {
        name: "Healthy Glow",
        tag: "skinHealthyGlowRating",
        description: "skinHealthyGlowRatingDescription",
      },
    ],
  },
  {
    category: "eyes",
    name: "eyes",
    mobileName: "eyes",
    emoji: "👁️",
    female: true,
    categoryTag: "eyesCategoryRating",
    categoryImproveTag: "eyesHowToImprove",
    subcategories: [
      {
        name: "Symmetry",
        tag: "eyesSymmetryRating",
        description: "eyesSymmetryRatingDescription",
      },
      {
        name: "Bright Eyes",
        tag: "eyesBrightEyesRating",
        description: "eyesBrightEyesRatingDescription",
      },
      {
        name: "Well-Groomed Eyebrows",
        tag: "eyesWellGroomedEyebrowsRating",
        description: "eyesWellGroomedEyebrowsRatingDescription",
      },
      {
        name: "No Dark Circles",
        tag: "eyesNoDarkCirclesRating",
        description: "eyesNoDarkCirclesRatingDescription",
      },
      {
        name: "Long Thick Eyelashes",
        tag: "eyesLongThickEyelashesRating",
        description: "eyesLongThickEyelashesRatingDescription",
      },
    ],
  },
  {
    category: "jawline",
    name: "jaw",
    mobileName: "jaw",
    emoji: "👨",
    female: true,
    categoryTag: "jawlineAndChinCategoryRating",
    categoryImproveTag: "jawlineAndChinHowToImprove",
    subcategories: [
      {
        name: "Strong Jawline",
        tag: "jawlineStrongJawlineRating",
        description: "jawlineStrongJawlineRatingDescription",
      },
      {
        name: "Symmetrical Chin",
        tag: "jawlineSymmetricalChinRating",
        description: "jawlineSymmetricalChinRatingDescription",
      },
      {
        name: "No Double Chin",
        tag: "jawlineNoDoubleChinRating",
        description: "jawlineNoDoubleChinRatingDescription",
      },
    ],
  },
  {
    category: "smile",
    name: "smile",
    mobileName: "smile",
    emoji: "😄",
    female: true,
    categoryTag: "teethAndSmileCategoryRating",
    categoryImproveTag: "teethAndSmileHowToImprove",
    subcategories: [
      {
        name: "White Teeth",
        tag: "teethWhiteTeethRating",
        description: "teethWhiteTeethRatingDescription",
      },
      {
        name: "Straight Teeth",
        tag: "teethStraightTeethRating",
        description: "teethStraightTeethRatingDescription",
      },
      {
        name: "Gum Health",
        tag: "teethGumHealthRating",
        description: "teethGumHealthRatingDescription",
      },
      {
        name: "Full Lips",
        tag: "teethFullLipsRating",
        description: "teethFullLipsRatingDescription",
      },
    ],
  },
  {
    category: "nose",
    name: "nose",
    mobileName: "nose",
    emoji: "👃",
    female: true,
    categoryTag: "noseCategoryRating",
    categoryImproveTag: "noseHowToImprove",
    subcategories: [
      {
        name: "Symmetry",
        tag: "noseSymmetryRating",
        description: "noseSymmetryRatingDescription",
      },
      {
        name: "Straight Nose Bridge",
        tag: "noseStraightNoseBridgeRating",
        description: "noseStraightNoseBridgeRatingDescription",
      },
      {
        name: "Proportional Size",
        tag: "noseProportionalSizeRating",
        description: "noseProportionalSizeRatingDescription",
      },
    ],
  },
  {
    category: "hair",
    name: "hair",
    mobileName: "hair",
    emoji: "💇‍♂️",
    female: true,
    categoryTag: "hairCategoryRating",
    categoryImproveTag: "hairHowToImprove",
    subcategories: [
      {
        name: "Healthy Hair",
        tag: "hairHealthyHairRating",
        description: "hairHealthyHairRatingDescription",
      },
      {
        name: "Hairline",
        tag: "hairHairlineRating",
        description: "hairHairlineRatingDescription",
      },
      {
        name: "Face Framing Style",
        tag: "hairFaceFramingStyleRating",
        description: "hairFaceFramingStyleRatingDescription",
      },
    ],
  },
  {
    category: "symmetry",
    name: "facial symmetry",
    mobileName: "balance",
    emoji: "⚖️",
    female: true,
    categoryTag: "facialSymmetryCategoryRating",
    categoryImproveTag: "facialSymmetryHowToImprove",
    subcategories: [
      {
        name: "Balanced Features",
        tag: "facialSymmetryBalancedFeaturesRating",
        description: "facialSymmetryBalancedFeaturesRatingDescription",
      },
      {
        name: "Golden Ratio",
        tag: "facialSymmetryGoldenRatioRating",
        description: "facialSymmetryGoldenRatioRatingDescription",
      },
    ],
  },
  {
    category: "cheekbones",
    name: "cheekbones",
    mobileName: "cheeks",
    emoji: "🦴",
    female: true,
    categoryTag: "cheekbonesCategoryRating",
    categoryImproveTag: "cheekbonesHowToImprove",
    subcategories: [
      {
        name: "High Cheekbones",
        tag: "cheekbonesHighCheekbonesRating",
        description: "cheekbonesHighCheekbonesRatingDescription",
      },
      {
        name: "Slightly Hollowed Cheeks",
        tag: "cheekbonesSlightlyHollowedCheeksRating",
        description: "cheekbonesSlightlyHollowedCheeksRatingDescription",
      },
    ],
  },
  {
    category: "facialHair",
    name: "facial hair",
    mobileName: "groom",
    emoji: "🧔",
    female: false,
    categoryTag: "facialHairCategoryRating",
    categoryImproveTag: "facialHairHowToImprove",
    subcategories: [
      {
        name: "Well-Groomed",
        tag: "facialHairWellGroomedRating",
        description: "facialHairWellGroomedRatingDescription",
      },
      {
        name: "Complementary To Face Shape",
        tag: "facialHairComplementaryToFaceShapeRating",
        description: "facialHairComplementaryToFaceShapeRatingDescription",
      },
    ],
  },
  {
    category: "bodyFatAndMuscleTone",
    name: "body",
    mobileName: "body",
    emoji: "💪",
    female: true,
    categoryTag: "bodyFatAndMuscleToneCategoryRating",
    categoryImproveTag: "bodyFatAndMuscleToneHowToImprove",
    subcategories: [
      {
        name: "Low Body Fat Percentage",
        tag: "bodyFatLowBodyFatPercentageRating",
        description: "bodyFatLowBodyFatPercentageRatingDescription",
      },
      {
        name: "Toned Muscles",
        tag: "bodyFatTonedMusclesRating",
        description: "bodyFatTonedMusclesRatingDescription",
      },
    ],
  },
  {
    category: "ears",
    name: "ears",
    mobileName: "ears",
    emoji: "👂",
    female: true,
    categoryTag: "earsCategoryRating",
    categoryImproveTag: "earsHowToImprove",
    subcategories: [
      {
        name: "Symmetrical Ears",
        tag: "earsSymmetricalEarsRating",
        description: "earsSymmetricalEarsRatingDescription",
      },
      {
        name: "Proper Size",
        tag: "earsProperSizeRating",
        description: "earsProperSizeRatingDescription",
      },
    ],
  },
  {
    category: "harmony",
    name: "harmony",
    mobileName: "harmony",
    emoji: "🎶",
    female: true,
    categoryTag: "facialHarmonyCategoryRating",
    categoryImproveTag: "facialHarmonyHowToImprove",
    subcategories: [
      {
        name: "Balanced Proportions",
        tag: "facialHarmonyBalancedProportionsRating",
        description: "facialHarmonyBalancedProportionsRatingDescription",
      },
      {
        name: "Youthful Appearance",
        tag: "facialHarmonyYouthfulAppearanceRating",
        description: "facialHarmonyYouthfulAppearanceRatingDescription",
      },
    ],
  },
  {
    category: "overall",
    name: "overall",
    mobileName: "overall",
    emoji: "✨",
    female: true,
    categoryTag: "overallRating",
    categoryImproveTag: "overallHowToImprove",
  },
];

export default function Results({
  currentAnalysis,
  result,
  showMobileChat,
  setShowMobileChat,
}: {
  currentAnalysis: any;
  result: any;
  showMobileChat: boolean;
  setShowMobileChat: any;
}) {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const [timeLeft, setTimeLeft] = useState(result?.analysisLength); // Start with the provided analysisLength in milliseconds

  useEffect(() => {
    const startTime = new Date(result.timestamp).getTime(); // Use the provided timestamp to determine the start
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime; // Calculate elapsed time in milliseconds
      const remainingTime = result?.analysisLength - elapsedTime; // Calculate the remaining time in milliseconds
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(interval); // Stop the countdown when it reaches zero
      }
    }, 100); // Update every 100ms for smoother progress bar movement

    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, [result?.analysisLength, result?.timestamp]);

  const progressPercentage = (timeLeft / result?.analysisLength) * 100;

  useEffect(() => {
    setCurrentCategoryIndex(0);
    setShowMobileChat(false);
    //eslint-disable-next-line
  }, [currentAnalysis]);

  useEffect(() => {
    setTimeLeft(result?.analysisLength);
    setShowMobileChat(false);
    //eslint-disable-next-line
  }, [result]);

  const handleNext = () => {
    if (currentCategoryIndex < currentAnalysisCategories.length - 1) {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
    }
  };

  let currentAnalysisCategories = result?.isFemale
    ? categories.filter((category) => category.female)
    : categories;

  const currentCategory = currentAnalysisCategories[currentCategoryIndex];

  return (
    <div className="w-full h-full">
      <div className="flex flex-col h-full">
        {/* Background image div */}
        <div className="relative flex-grow">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${currentAnalysis?.imageUrl})`,
            }}
          ></div>
          <div className="absolute top-auto sm:top-0 bottom-3 sm:bottom-auto left-0 sm:top-2 sm:left-2 p-1 sm:p-2 bg-black bg-opacity-40 sm:bg-opacity-50">
            <div className="text-lg sm:text-2xl font-black mb-1 sm:mb-3">
              {currentAnalysis?.firstName}
            </div>
            {currentAnalysisCategories.map((category, index) => (
              <div
                key={`${category.categoryTag}-${index}`}
                className={`flex justify-between items-center text-[13px] sm:text-[14px] ${
                  index === categories.length - 1 ? "" : "mb-0 sm:mb-1"
                }`}
              >
                <div className="hidden sm:block mr-3">
                  {category.emoji}{" "}
                  {category.name === "facial symmetry"
                    ? "symmetry"
                    : category.name}
                </div>
                <div className="block sm:hidden mr-1">
                  {category.emoji} {category.mobileName}
                </div>
                <div>{result?.[category.categoryTag]}/10</div>
              </div>
            ))}
          </div>
          <div className="block sm:hidden absolute bottom-3 right-1">
            <Button
              variant="outline"
              className="bg-black bg-opacity-70 h-9 w-32"
              onClick={() => setShowMobileChat(!showMobileChat)}
            >
              chat <MessageSquare fill="white" className="w-4 h-4 ml-2" />
            </Button>
          </div>
          <div
            className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-100"
            style={{ top: isMobile ? "95%" : "50%" }}
          ></div>
        </div>
        <div className="flex-grow-0 pt-5 sm:pt-8">
          <div className="flex justify-between items-center">
            {/* Left Arrow Button - hide if at first category */}
            {currentCategoryIndex > 0 ? (
              <Button onClick={handlePrevious} className="p-2 sm:p-4">
                ⬅️
              </Button>
            ) : (
              <div />
            )}

            {/* Display current category */}
            <div className="px-6 w-full">
              <div className="flex justify-center sm:justify-between items-center">
                <div className="flex items-center font-bold sm:font-normal">
                  <div className="text-2xl sm:text-3xl mr-3">
                    {currentCategory.emoji}
                  </div>
                  <div className="hidden sm:block text-xl sm:text-3xl mr-3">
                    {currentCategory.name} rating:
                  </div>
                  <div className="block sm:hidden text-xl sm:text-3xl mr-3">
                    {currentCategory.mobileName} rating:
                  </div>
                  <div className="text-xl sm:text-3xl">
                    {result?.[currentCategory.categoryTag]}/10
                  </div>
                </div>
                {currentCategory.subcategories?.length && (
                  <div className="hidden sm:flex flex justify-end">
                    <Dialog>
                      <DialogTrigger>
                        <Button variant="outline">
                          breakdown <MoveDiagonal className="w-4 h-4 ml-2" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogDescription>
                            <div className="flex items-center">
                              <div className="text-3xl mr-3">
                                {currentCategory.emoji}
                              </div>
                              <div className="text-3xl mr-3">
                                {currentCategory.name} rating:
                              </div>
                              <div className="text-3xl">
                                {result?.[currentCategory.categoryTag]}/10
                              </div>
                            </div>
                            <div className="mt-4">
                              <span className="font-black">
                                how to improve:
                              </span>{" "}
                              {result?.[currentCategory.categoryImproveTag]
                                ?.toLowerCase()
                                .replace(/^[.\s]+|[.\s]+$/g, "")}
                            </div>
                            <Separator className="my-6" />
                            {currentCategory.subcategories?.length &&
                              currentCategory.subcategories?.length > 0 && (
                                <div>
                                  {currentCategory.subcategories.map(
                                    (subcategory, index) => (
                                      <div key={index} className="mb-6">
                                        <div className="font-extrabold text-xl">
                                          {subcategory.name?.toLowerCase()}:{" "}
                                          {result?.[subcategory.tag]}/10
                                        </div>

                                        <div className="mt-2">
                                          {result?.[subcategory.description]
                                            ?.toLowerCase()
                                            .replace(/^[.\s]+|[.\s]+$/g, "")}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                          </DialogDescription>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                  </div>
                )}
              </div>
              {currentCategory.subcategories?.length && (
                <div className="block sm:hidden mt-6 sm:mt-8 w-full">
                  <Dialog>
                    <DialogTrigger className="w-full">
                      <Button variant="outline" className="w-full">
                        breakdown <MoveDiagonal className="w-4 h-4 ml-2" />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="max-h-[90vh]">
                      <DialogHeader>
                        <DialogDescription className="text-left">
                          <div className="flex items-center">
                            <div className="text-2xl sm:text-3xl mr-3">
                              {currentCategory.emoji}
                            </div>
                            <div className="text-2xl sm:text-3xl mr-3">
                              {currentCategory.name} rating:
                            </div>
                            <div className="text-2xl sm:text-3xl">
                              {result?.[currentCategory.categoryTag]}/10
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="font-black">how to improve:</span>{" "}
                            {result?.[currentCategory.categoryImproveTag]
                              ?.toLowerCase()
                              .replace(/^[.\s]+|[.\s]+$/g, "")}
                          </div>
                          <Separator className="my-4 sm:my-6" />
                          {currentCategory.subcategories?.length &&
                            currentCategory.subcategories?.length > 0 && (
                              <div>
                                {currentCategory.subcategories.map(
                                  (subcategory, index) => (
                                    <div
                                      key={index}
                                      className={`${
                                        index ===
                                        currentCategory.subcategories.length - 1
                                          ? ""
                                          : "mb-4 sm:mb-6"
                                      }`}
                                    >
                                      <div className="font-extrabold text-lg sm:text-xl">
                                        {subcategory.name?.toLowerCase()}:{" "}
                                        {result?.[subcategory.tag]}/10
                                      </div>

                                      <div className="mt-1 sm:mt-2">
                                        {result?.[subcategory.description]
                                          ?.toLowerCase()
                                          .replace(/^[.\s]+|[.\s]+$/g, "")}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                        </DialogDescription>
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              {currentCategory.name === "overall" && (
                <div className="block sm:hidden mt-6 sm:mt-8 w-full">
                  <Dialog>
                    <DialogTrigger className="w-full">
                      <Button variant="outline" className="w-full">
                        breakdown <MoveDiagonal className="w-4 h-4 ml-2" />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="max-h-[90vh]">
                      <DialogHeader>
                        <DialogDescription className="text-left">
                          <div className="flex items-center">
                            <div className="text-2xl sm:text-3xl mr-3">
                              {currentCategory.emoji}
                            </div>
                            <div className="text-2xl sm:text-3xl mr-3">
                              {currentCategory.name} rating:
                            </div>
                            <div className="text-2xl sm:text-3xl">
                              {result?.[currentCategory.categoryTag]}/10
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="font-black">how to improve:</span>{" "}
                            {result?.[currentCategory.categoryImproveTag]
                              ?.toLowerCase()
                              .replace(/^[.\s]+|[.\s]+$/g, "")}
                          </div>
                        </DialogDescription>
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </div>
              )}
              <div className="hidden sm:block mt-4">
                <span className="font-black">how to improve:</span>{" "}
                {result?.[currentCategory.categoryImproveTag]
                  ?.toLowerCase()
                  .replace(/^[.\s]+|[.\s]+$/g, "")}
              </div>
            </div>
            {/* Right Arrow Button - hide if at last category */}
            {currentCategoryIndex < currentAnalysisCategories.length - 1 ? (
              <Button onClick={handleNext} className="p-2 sm:p-4">
                ➡️
              </Button>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-8">
          <div className="w-full h-[1px] overflow-hidden">
            <div
              style={{ width: `${progressPercentage}%` }}
              className="h-full bg-white transition-all duration-500"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
